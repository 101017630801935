/* eslint-disable @typescript-eslint/explicit-function-return-type */

import type { RouteLocationNormalized } from 'vue-router'

import ChooseAgency from '@/views/auth/ChooseAgency.vue'
import UserProfile from '@/views/auth/UserProfile.vue'

export default [
  {
    name: 'choose-agency',
    path: '/choose-agency',
    component: ChooseAgency,
    meta: {
      agencyRequired: false,
      title: 'Choose Agency',
      legacy: true,
    },
    props: (to: RouteLocationNormalized) => ({
      instance: to.query.instance,
      agency: to.query.agency,
      redirectPath: to.query.redirectPath,
    }),
  },
  {
    name: 'user-profile',
    path: '/profile',
    component: UserProfile,
    meta: {
      agencyRequired: false,
      title: 'Profile',
      legacy: true,
    },
  },
]
