<template>
  <ElCheckbox
    class="checkbox"
    :class="{
      [`checkbox--${type}`]: type,
      'checkbox--disabled': disabled,
    }"
    :disabled="disabled"
    :model-value="modelValue"
    @update:modelValue="$emit('update:modelValue', $event)"
    @change="$emit('change', $event)"
  >
    <div class="checkbox__body">
      <slot>
        <SimpleText v-if="label" bold type="regular" size="micro">
          {{ label }}
        </SimpleText>
      </slot>

      <slot name="suffix" />
    </div>
  </ElCheckbox>
</template>

<script>
import SimpleText from '@/patterns/SimpleText'

export const types = ['simple', 'button']

export default {
  name: 'Checkbox',
  components: {
    SimpleText,
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      validator: (value) => types.includes(value),
      default: 'simple',
    },
  },
  emits: ['update:modelValue', 'change'],
}
</script>

<style lang="scss" scoped>
.checkbox {
  border: 1px solid transparent;
  border-radius: 4px;
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  outline: 0;
  padding: var(--app-spacing-1);
  height: auto;

  :deep(.el-checkbox__inner) {
    // we focus the parent instead so we don't want this to ever change it's border-color.
    border-color: var(--el-border-color) !important;
  }

  :deep(.el-checkbox__label) {
    width: 100%;
    padding-left: var(--app-spacing-2);
  }

  :deep(.el-checkbox__input) {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: normal;
    pointer-events: none;
  }

  &--button {
    background-color: #fff;
    border-color: var(--el-border-color);
    padding: var(--app-spacing-2) var(--app-spacing-5);

    :deep(.el-checkbox__label) {
      padding-left: var(--app-spacing-4);
    }
  }

  &:not(&--disabled) {
    &:hover {
      background-color: var(--el-border-color-extra-light);
    }

    &:focus-within {
      border-color: var(--el-color-primary-light-2);
    }
  }
}
</style>
