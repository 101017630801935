<template>
  <ElDialog
    :model-value="visible"
    top="1vh"
    width="500px"
    align-center
    :before-close="cancel"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    destroy-on-close
    append-to-body
    title="Switch Agency"
  >
    <template #header>
      <DialogHeader :icon="Switch">
        Automatic Instance/Agency Switcher

        <template #subheader>
          <SimpleText tag="h3" size="small">
            The URL contains a different instance or agency than your current
            one.
          </SimpleText>
        </template>
      </DialogHeader>
    </template>

    <div class="instance-agency-info">
      <SimpleText type="bold" size="small" tag="p">
        Current agency:
      </SimpleText>
      <SimpleText type="regular" size="small" tag="p">
        {{ generateAgencyName(currentAgency) }} on
        {{ currentInstance }}
      </SimpleText>
    </div>

    <div class="instance-agency-info">
      <SimpleText type="bold" size="small" tag="p"> New agency: </SimpleText>
      <SimpleText type="regular" size="small" tag="p">
        {{ generateAgencyName(newAgency) }} on
        {{ newInstance }}
      </SimpleText>
    </div>

    <SimpleText type="regular" tag="div"> Do you want to change?</SimpleText>

    <template #footer>
      <ElButton type="primary" @click="switchAgency(false)"> Yes </ElButton>
      <ElButton type="primary" @click="switchAgency(true)">
        For this browser tab only
      </ElButton>
      <ElButton @click="cancel"> No </ElButton>
    </template>
  </ElDialog>
</template>

<script setup lang="ts">
import { Switch } from '@element-plus/icons-vue'

import { generateAgencyName } from '@/lib/utils'
import DialogHeader from '@/patterns/DialogHeader'
import SimpleText from '@/patterns/SimpleText'

interface Props {
  visible: boolean
  currentInstance: string
  currentAgency: string
  newInstance: string
  newAgency: string
}
const { visible, currentInstance, currentAgency, newInstance, newAgency } =
  defineProps<Props>()

const emit = defineEmits<{
  (
    e: 'switchAgency',
    { useSessionStorage }: { useSessionStorage: boolean }
  ): void
  (e: 'cancel'): void
}>()

const switchAgency = (useSessionStorage: boolean) => {
  emit('switchAgency', { useSessionStorage })
}

const cancel = () => {
  emit('cancel')
}
</script>

<style lang="scss" scoped>
.instance-agency-info {
  margin-bottom: 16px;
}
</style>
