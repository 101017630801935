<template>
  <span class="share-link-icon" @click="copyToClipboard($event)">
    <ElIcon aria-label="Share link"><ShareIcon title="Share link" /></ElIcon>
  </span>
</template>

<script lang="ts" setup>
import { Share as ShareIcon } from '@element-plus/icons-vue'
import { useClipboard } from '@vueuse/core'
import { ElMessage } from 'element-plus'
import { inject } from 'vue'

import { AgencyName, InstanceName } from '@/@types/Config'

const { copy } = useClipboard()
const agency = inject('agency') as AgencyName
const instance = inject('instance') as InstanceName

async function copyToClipboard(e: Event) {
  e.stopPropagation()

  const searchParamsObj: Record<string, string> = {}

  if (agency) {
    searchParamsObj.agency = agency
  }
  if (instance) {
    searchParamsObj.instance = instance
  }

  const shareLinkSearchParams = new URLSearchParams(searchParamsObj).toString()
  const shareLink = shareLinkSearchParams
    ? `${window.location.href}?${shareLinkSearchParams}`
    : window.location.href

  try {
    await copy(shareLink)
    ElMessage.success({
      message: `Share link copied to clipboard!`,
      duration: 1000,
    })
  } catch {
    ElMessage.error('Unable to copy share link to clipboard')
  }
}
</script>
