const PLRN_KEY = 'pio-plrn'

const plrn = sessionStorage.getItem(PLRN_KEY) ?? localStorage.getItem(PLRN_KEY)

const setPlrn = (
  plrn: string,
  location: 'localStorage' | 'sessionStorage' = 'localStorage'
): void => {
  // clear sessionStorage plrn if location is localStorage
  if (location === 'localStorage') {
    sessionStorage.removeItem(PLRN_KEY)
  }
  window[location].setItem(PLRN_KEY, plrn)
}

const [, instance, agency] = (plrn || '').split(':')

export { plrn, setPlrn, instance, agency }
