import { computed, ComputedRef, ref } from 'vue'

export interface UseDialogResult {
  isVisible: ComputedRef<boolean>
  hideDialog: () => void
  showDialog: () => void
}

export default function useDialog(): UseDialogResult {
  const isVisible = ref(false)

  const showDialog = (): void => {
    isVisible.value = true
  }

  const hideDialog = (): void => {
    isVisible.value = false
  }

  const readonlyIsVisible = computed((): boolean => isVisible.value)

  return {
    isVisible: readonlyIsVisible,
    hideDialog,
    showDialog,
  }
}
