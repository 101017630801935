import { RouteLocationNormalized, RouteLocationRaw } from 'vue-router'

import { plrn, instance, agency } from '@/plrn'

export const agencyGuard = async (
  to: RouteLocationNormalized
): Promise<RouteLocationRaw | boolean> => {
  // if the query params contain an instance or agency, and these differ from the current ones, update the store
  const { instance: queryInstance, agency: queryAgency } = to.query
  const isQueryAgencyDifferent = queryAgency && queryAgency !== agency
  const isQueryInstanceDifferent = queryInstance && queryInstance !== instance

  if (
    to.name !== 'choose-agency' &&
    (isQueryInstanceDifferent || isQueryAgencyDifferent)
  ) {
    console.info(
      'Redirecting to choose agency as instance and agency in query params differ from current session'
    )
    return {
      name: 'choose-agency',
      query: {
        ...to.query,
        redirectPath: to.fullPath,
      },
    }
  }

  if (to.meta?.agencyRequired === false || plrn) {
    return true
  }

  // User needs to choose an agency
  console.info('Redirecting user to choose agency')
  return { name: 'choose-agency' }
}
