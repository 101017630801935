import { AgencyConfig } from '@/@types/Config'
import dayjs from '@/lib/dayjs'
import deepFreeze from '@/lib/utils/deepFreeze'

// The date we want to block any new quoting.
const blockQuotingFrom = dayjs('2024-12-30')

const agencyConfig: AgencyConfig = {
  flags: {
    allowCreatingNewClaim: true,
    allowEditProductAtRenewal: true,
    alwaysAllowClaimCalculationAssessment: true,
    sendSubscriptionIdOnCreatePolicyBasketCheckout: true,
    usePca: true,
    showCalculationsList: true,
    customerNotesEnabled: true,
    allowEditClaimTitle: true,
    allowAddLossRecoveredAmount: false,
    allowDocumentRequestFromVet: false,
    allowDifferentPostcodes: true,
    dobAsRatingFactor: false,
    blockQuoting: dayjs().isSameOrAfter(blockQuotingFrom, 'day'),
    enableNewFlow: true, // will be used to force SGP enable for staging
    forceDisableNewFlow: false,
    enableEmailOrder: true,
    nudgeForNewFlow: true,
    disableOldQuotingFlow: true,
    displayCancellationWarningText: true,
    displayCustomerFlagsDropdown: true,
    displayFullAddressOnNewBusiness: false,
    allowEditLegacyCustomer: true,
    disableEditEffectiveDate: true,
    disableEditInceptionDate: true,
    enableNewBusinessNextDayInceptionDate: true,
    enableNewDocumentsTab: true,
    removeSubscriptionsTab: true,
    displayWaitingPeriod: true,
    enableCommunication: true,
    enableUserHistory: false,
    enableRetainedPremiumsCalculations: true,
    enableReinstatement: false,
    enableSendingAffiliateCode: false,
    enableResetSmsMfa: false,
    enableNewRenewalUi: false,
    newFinancialTabIsEnabled: false,
    enableCerfScore: true,
    ibaPetTimelineEnabled: false,
    enableNewBusinessEditPaymentDate: false,
    enableCurrentBusinessEditPaymentDate: false,
    isPciPalEnabled: true,
  },
  currency: {
    code: 'USD',
    symbol: '$',
    symbolPosition: 'prepend',
  },
  copaymentType: 'reimbursement',
  excessType: 'deductible',
  locale: 'en-US',
  dateFormatForLocale: 'MMM D YYYY',
  algolia: {
    breedLookup: {
      id: 'ORKMXLHYI4',
      index: 'dev_usa_pet_list',
      key: 'a362df8e58dc096d24d23ae260d27450',
    },
  },
  paymentProviders: [
    {
      id: 'speik',
      method: 'card',
      label: 'Card (Speik)',
      providerConfig: {
        speik: {
          allowDevMode: true,
          iframeSrc:
            'https://uk-preprod.aeriandi.com/Player/app/v1/78480/4432469/AgentPayFramework/',
          paymentAccount: 'ManyPetsUSStag',
        },
      },
    },
    {
      id: 'stripe',
      method: 'card',
      label: 'Card (Stripe)',
      providerConfig: {
        stripe: {
          publishable_key:
            'pk_test_51IB1NGEofu7XXbfwUu35rWBu9LEcNBhQpsHsgnqzgJtaUiyEot7NVCHRM9teKbVvHuWkHeAkLRBph8rQM3c33myy00MtHGyzk5',
        },
      },
    },
    {
      id: 'pcipal',
      method: 'card',
      label: 'Card (PciPal)',
      providerConfig: undefined,
    },
  ],
  globalProductLines: ['accident-illness', 'wellness'],
  claims: {
    docTypes: [
      'MEDICAL_HISTORY',
      'MEDICAL_HISTORY_18_MONTHS',
      'INVOICE',
      'DOCTOR_OF_VET_MEDICINE_LETTER',
      'OTHER',
    ],
  },
  vetPractices: {
    country: 'US',
    corporateNetworks: [],
  },
  usePcaStateCode: true,
  supportedStates: [
    'AK',
    'AL',
    'AR',
    'AZ',
    'CA',
    'CO',
    'CT',
    'DC',
    'DE',
    'FL',
    'GA',
    'HI',
    'IA',
    'IL',
    'IN',
    'KS',
    'KY',
    'LA',
    'MD',
    'ME',
    'MI',
    'MO',
    'MS',
    'MT',
    'NC',
    'ND',
    'NE',
    'NH',
    'NJ',
    'NM',
    'NV',
    'NY',
    'OH',
    'OK',
    'OR',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VA',
    'VT',
    'WA',
    'WI',
    'WV',
    'WY',
  ],
  unsupportedStatesForNewProductsPreInception: ['IL', 'ME', 'WA'],
  paymentTypes: ['USAAddress', 'USAAccount'],
  customerCountryIsRequired: false,
  customerStateIsRequired: true,
  updatePetStateOnCustomerEdit: true,
  legacyProductLines: [],
  quotableProductLines: ['accident-illness'],
  sgpProductLines: [],
  newFlowEnabledProductLines: ['accident-illness', 'wellness'],
  maxPetAge: 21,
}

export default deepFreeze(agencyConfig)
