import { sumBy } from 'lodash-es'

import { ProductLineName } from '@/@types/Config'
import { getConfigForFeature } from '@/lib/appConfig'
import dayjs, { DayJsParsable } from '@/lib/dayjs'

import { PaymentMethodDetails } from '../Shop/PaymentMethodDetails'
import { ProductPriceDetails, ProductTotalPrice } from '../Shop/ProductPrice'

import { AccountSummaryItem } from './AccountSummaryItem'
import { AccountSummaryItemHydrated } from './AccountSummaryItemHydrated'

export type Title = 'Mr' | 'Mrs' | 'Miss' | 'Ms' | 'Mx' | 'Prof' | 'Dr' | string
export type Species = 'dog' | 'cat'
export type PetGender = 'male' | 'female'
export type PedigreeType = 'pedigree' | 'mixed' | 'cross'
export type DocumentType =
  | 'certificate'
  | 'ipid_value'
  | 'ipid_regular'
  | 'ipid_complete'
  | 'ipid_travel'
  | 'ipid_loss_theft'
  | 'ipid_moneyback'
  | 'handbook'

export interface AccountSummary {
  loadedOnDate?: string
  customer: AccountSummaryCustomer
  pets: AccountSummaryPet[]
  payment?: AccountSummaryPayment
  price: ProductTotalPrice
  hydrated?: never
}

export interface AccountSummaryCustomer {
  id: string
  email: string
  title?: Title
  firstName?: string
  lastName?: string
  address: Partial<AccountSummaryAddress>
  phone?: string
  dateOfBirth?: string
  ssn?: string
}

export interface AccountSummaryPet {
  id: string
  name: string
  dateOfBirth: string
  species: Species
  gender: PetGender
  breed: string
  breedLabel: string
  postcode: string
  state?: string
  items: AccountSummaryItem[]
  pedigreeType?: PedigreeType
  spayedNeutered?: boolean
  value?: number
  registrationNumber?: string
  cerfScore?: number | null
}

export interface AccountSummaryPayment {
  day: number
  schedule: PaymentSchedule[]
  inArrears: boolean
  paymentMethodDetails: Nullable<PaymentMethodDetails>
  collectOnPaymentDay: boolean
}

export interface AccountSummaryAddress {
  line1: string
  line2?: string
  city: string
  country: string
  stateOrCounty: string
  postcode: string
}

export type AccountSummaryPaymentType = 'regular' | 'adjustment' | 'combined'

export interface PaymentSchedule {
  due: string
  total: number
  type: AccountSummaryPaymentType
  coverPeriod: Nullable<{
    startDate: string
    endDate: string
  }>
}

export interface AccountSummaryHydrated
  extends Omit<AccountSummary, 'pets' | 'hydrated'> {
  customer: AccountSummaryCustomerHydrated
  pets: AccountSummaryPetHydrated[]
  price: ProductTotalPrice
  hydrated: true
  isSgp: boolean
}

export interface AccountSummaryCustomerHydrated extends AccountSummaryCustomer {
  uuid: string
}

export interface AccountSummaryPetHydrated
  extends Omit<AccountSummaryPet, 'items'> {
  age: Nullable<PetAge>
  items: AccountSummaryItemHydrated[]
  cerfScore?: number | null
}

export interface PetAge {
  months: number
  years: number
}

export interface Document {
  id: DocumentType
  type: 'certificate' | 'ipid' | 'handbook'
  url: string
  itemId: string
}

export interface AccountSummaryDocuments {
  documents: Document[]
}

export const getPetMonthlyPrice = (
  pet: AccountSummaryPetHydrated
): Partial<ProductPriceDetails> => ({
  total: sumBy(pet.items, 'price.monthlyTotal.total'),
  discount: sumBy(pet.items, 'price.monthlyTotal.discount'),
  tax: sumBy(pet.items, 'price.monthlyTotal.tax'),
  subtotal: sumBy(pet.items, 'price.monthlyTotal.subtotal'),
})

export const calculateTotalMonthlyPrice = (
  accountSummary: AccountSummaryHydrated
): Omit<ProductPriceDetails, 'discountPercentage'> => {
  const petsPrices = accountSummary.pets.map(getPetMonthlyPrice)

  return {
    total: sumBy(petsPrices, 'total'),
    discount: sumBy(petsPrices, 'discount'),
    tax: sumBy(petsPrices, 'tax'),
    subtotal: sumBy(petsPrices, 'subtotal'),
  }
}

export const getAccountSummaryItemHydratedById = (params: {
  accountSummaryHydrated: AccountSummaryHydrated
  id: string
}): Nullable<AccountSummaryItemHydrated> => {
  const { accountSummaryHydrated, id } = params

  const result = accountSummaryHydrated.pets
    ?.flatMap((pet) => pet.items)
    .find((item) => item.id === id)

  return result ?? null
}

export const petHasActiveProductLine = (params: {
  pet: AccountSummaryPetHydrated
  productLine: ProductLineName
}): boolean => {
  const { pet, productLine } = params

  return pet.items.some(
    (item: AccountSummaryItemHydrated): boolean =>
      item.productLine === productLine &&
      dayjs.utc(item.endDate).isAfter(dayjs.utc(), 'day')
  )
}

export const getNextPaymentDetails = (params: {
  payment: AccountSummaryPayment
}): Nullable<PaymentSchedule> => {
  return (
    params.payment.schedule.find(
      (paymentDate) =>
        paymentDate.due && dayjs().isBefore(dayjs(paymentDate.due), 'day')
    ) ?? null
  )
}
export const getNextFuturePaymentScheduleItem = (params: {
  schedule: PaymentSchedule[]
}): Nullable<PaymentSchedule> => {
  const { schedule } = params

  const result = schedule.find((scheduleItem: PaymentSchedule) => {
    const dueDate = dayjs(scheduleItem.due)
    if (dueDate.isAfter(dayjs(), 'day')) {
      return true
    }

    return false
  })

  return result ?? null
}

export const getAllAccountSummaryItemsHydrated = (params: {
  accountSummaryHydrated: AccountSummaryHydrated
}): AccountSummaryItemHydrated[] => {
  const { accountSummaryHydrated } = params

  return accountSummaryHydrated.pets.flatMap((pet) => pet.items)
}

export const inactivePets = (params: {
  pets: AccountSummaryPetHydrated[]
}): AccountSummaryPetHydrated[] => {
  const { pets } = params

  return pets.filter((pet) => {
    return pet.items?.some(
      (item: AccountSummaryItemHydrated): boolean =>
        item.policy?.status === 'CANCELLED' || item.policy?.status === 'VOID'
    )
  })
}

export const filterActivePetsAndRemoveInactiveItems = (params: {
  pets: AccountSummaryPetHydrated[]
}): AccountSummaryPetHydrated[] => {
  const { pets } = params

  const petsWithAtLeastOneActiveProduct = pets.filter((pet) => {
    return pet.items?.some(
      (item: AccountSummaryItemHydrated): boolean =>
        item.policy?.status !== 'CANCELLED' && item.policy?.status !== 'VOID'
    )
  })

  const petsWithActiveItemsOnly = petsWithAtLeastOneActiveProduct.map((pet) => {
    const activeItems =
      pet?.items.filter(
        (item: AccountSummaryItemHydrated): boolean =>
          item.policy?.status !== 'CANCELLED' && item.policy?.status !== 'VOID'
      ) ?? []

    return {
      ...pet,
      items: activeItems,
    }
  })

  return petsWithActiveItemsOnly
}

export const accountSummaryHasActiveItemAtDate = (params: {
  accountSummaryHydrated: AccountSummaryHydrated
  date: DayJsParsable
}): boolean => {
  const { accountSummaryHydrated, date } = params
  return accountSummaryHydrated.pets
    .flatMap((pet) => pet.items)
    .filter(
      (item) =>
        item.policy?.status === 'PRE_INCEPTION' ||
        item.policy?.status === 'ON_RISK'
    )
    .some((item) => dayjs(date).isSameOrAfter(dayjs(item.startDate), 'day'))
}

export const validateCustomerBeforePurchase = (
  accountCustomer: any
): string[] => {
  const issues: string[] = []

  if (typeof accountCustomer !== 'object' || accountCustomer === null) {
    issues.push(
      'The account customer information provided is not an object or is null.'
    )
    return issues
  }

  const customer = accountCustomer as { [key: string]: unknown }

  if (!customer.email) {
    issues.push('The email is missing or not in the correct format.')
  }
  if (!customer.firstName) {
    issues.push('The first name is missing or not in the correct format.')
  }
  if (!customer.lastName) {
    issues.push('The last name is missing or not in the correct format.')
  }
  if (!customer.phone) {
    issues.push('The phone number is missing or not in the correct format.')
  }
  if (!customer.dateOfBirth && getConfigForFeature('flags.dobAsRatingFactor')) {
    issues.push('The date of birth is missing or not in the correct format.')
  }

  if (typeof customer.address !== 'object' || customer.address === null) {
    issues.push('The address information is missing or not an object.')
  } else {
    const address = customer.address as { [key: string]: unknown }

    if (!address.line1) {
      issues.push('Address line one is missing or not in the correct format.')
    }
    if (!address.postcode) {
      issues.push(
        'The postcode in the address is missing or not in the correct format.'
      )
    }
  }

  return issues
}

export const accountSummaryHasActiveItems = (params: {
  accountSummaryHydrated: AccountSummaryHydrated
}): boolean => {
  const { accountSummaryHydrated } = params

  return accountSummaryHydrated.pets
    .flatMap((pet) => pet.items)
    .some(
      (item) =>
        item.policy?.status === 'PRE_INCEPTION' ||
        item.policy?.status === 'ON_RISK'
    )
}
