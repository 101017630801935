<template>
  <SimpleLayout>
    <section class="dashboard">
      <section class="system-information">
        <img
          width="72"
          height="72"
          src="@/assets/icons/rocket-dark.svg"
          alt="Policy Administration System"
        />

        <Heading tag="h1" size="large">Search</Heading>

        <div class="environment">
          <ElTooltip placement="top" :content="agencyName">
            <img width="32" height="32" :src="siteLogoUrl" :alt="agencyName" />
          </ElTooltip>
          <div class="environment--information">
            <SimpleText size="micro">
              Environment: <span aria-label="Environment">{{ instance }}</span>
            </SimpleText>
            <RouterLink :to="{ name: 'choose-agency' }">
              <SimpleText underline size="micro"> Change agency </SimpleText>
            </RouterLink>
          </div>
        </div>

        <SimpleText size="nano" tag="div">
          Version <span aria-label="Version">{{ buildVersion }}</span>
        </SimpleText>
        <SimpleText size="nano" tag="div">
          Last Updated:
          <span aria-label="Last updated">
            <FormattedDate :value="buildTime" format="longDatetime" />
          </span>
        </SimpleText>

        <div class="actions">
          <button
            v-if="canCreateNewQuote"
            class="action action--new-quote"
            type="button"
            @click="handleOnCreateNewQuote"
          >
            <ElIcon aria-hidden="true"><Plus /></ElIcon>
            <SimpleText size="micro">Start quote</SimpleText>
          </button>
        </div>
      </section>
      <section class="search-actions">
        <ElForm label-position="top" :model="formData">
          <div v-if="canSeeSearch('customer')" class="search-action">
            <img
              class="search-avatar"
              src="@/assets/icons/people.svg"
              alt="Customer search"
            />
            <ElFormItem prop="customerSearchQuery" label="Customer">
              <ElInput
                id="customerSearchQuery"
                v-model="formData.customerSearchQuery"
                placeholder="(name, email address, postcode, telephone number)"
                class="search-field"
                @keypress.enter.prevent="search('customer')"
              >
                <template #prefix>
                  <ElIcon class="el-input__icon"><Search /></ElIcon>
                </template>
              </ElInput>
            </ElFormItem>
          </div>
          <div v-if="canSeeSearch('policy')" class="search-action">
            <img
              class="search-avatar"
              src="@/assets/icons/policy-doc.svg"
              alt="Policy search"
              @keypress.enter.prevent="search('policy')"
            />
            <ElFormItem prop="policySearchQuery" label="Policy">
              <ElInput
                id="policySearchQuery"
                v-model="formData.policySearchQuery"
                placeholder="(name, ssn, email address, policy reference)"
                class="search-field"
                @keypress.enter.prevent="search('policy')"
              >
                <template #prefix>
                  <ElIcon class="el-input__icon"><Search /></ElIcon>
                </template>
              </ElInput>
            </ElFormItem>
          </div>
          <div v-if="canSeeSearch('claim')" class="search-action">
            <img
              class="search-avatar"
              src="@/assets/icons/claim-doc.svg"
              alt="Claim search"
            />
            <ElFormItem prop="claimSearchQuery" label="Claim">
              <ElInput
                id="claimSearchQuery"
                v-model="formData.claimSearchQuery"
                placeholder="(claim reference, loss reference, policy uuid)"
                class="search-field"
                @keypress.enter.prevent="search('claim')"
              >
                <template #prefix>
                  <ElIcon class="el-input__icon"><Search /></ElIcon>
                </template>
              </ElInput>
            </ElFormItem>
          </div>
          <div v-if="canSeeSearch('quote')" class="search-action">
            <img
              class="search-avatar"
              src="@/assets/icons/quote-doc.svg"
              alt="Quote search"
            />
            <ElFormItem prop="quoteSearchQuery" label="Quote">
              <ElInput
                id="quoteSearchQuery"
                v-model="formData.quoteSearchQuery"
                placeholder="(name, email address, quote reference)"
                class="search-field"
                @keypress.enter.prevent="search('quote')"
              >
                <template #prefix>
                  <ElIcon class="el-input__icon"><Search /></ElIcon>
                </template>
              </ElInput>
            </ElFormItem>
          </div>
          <div v-if="canSeeSearch('vet')" class="search-action">
            <img
              class="search-avatar"
              src="@/assets/icons/vet-practices.svg"
              alt="Vet search"
            />
            <ElFormItem prop="vetSearchQuery" label="Vet">
              <ElInput
                id="vetSearchQuery"
                v-model="formData.vetSearchQuery"
                placeholder="(name, address, email address, postcode)"
                class="search-field"
                @keypress.enter.prevent="search('vet')"
              >
                <template #prefix>
                  <ElIcon class="el-input__icon"><Search /></ElIcon>
                </template>
              </ElInput>
            </ElFormItem>
          </div>
        </ElForm>
      </section>
    </section>
  </SimpleLayout>
</template>

<script lang="ts" setup>
import { Plus, Search } from '@element-plus/icons-vue'
import { computed, ref, inject } from 'vue'
import { useRouter } from 'vue-router'

import FormattedDate from '@/components/FormattedDate'
import { usePermissions } from '@/composables/usePermissions'
import SimpleLayout from '@/layouts/SimpleLayout.vue'
import { getConfigForFeature } from '@/lib/appConfig'
import { getAgencyLogoUrl } from '@/lib/assetsUrls'
import { getBuildTime, getBuildVersion } from '@/lib/build'
import { useBasketStore } from '@/lib/store/basket/useBasket'
import { useQuoteAndBuy } from '@/lib/store/quoteAndBuy/useQuoteAndBuy'
import { generateAgencyName } from '@/lib/utils'
import { hasAnyPermission, hasPermission } from '@/lib/utils/permissions'
import { newFlowEnabled } from '@/lib/utils/sgp'
import Heading from '@/patterns/Heading'
import SimpleText from '@/patterns/SimpleText'
type SearchType = 'customer' | 'policy' | 'claim' | 'quote' | 'vet'

const formData = ref({
  customerSearchQuery: '',
  policySearchQuery: '',
  claimSearchQuery: '',
  quoteSearchQuery: '',
  vetSearchQuery: '',
})
const basketStore = useBasketStore()
const router = useRouter()
const quoteAndBuyStore = useQuoteAndBuy()

const agency = inject('agency') as string
const instance = inject('instance') as string
const { agencyPermissions } = usePermissions()

const agencyName = generateAgencyName(agency)

const siteLogoUrl = computed(() =>
  getAgencyLogoUrl({
    agency,
    size: 'medium',
    variant: 'dark',
  })
)

const canCreateNewQuote = computed(
  () =>
    hasPermission('quotes:write', agencyPermissions.value) &&
    !getConfigForFeature('flags.blockQuoting')
)

const handleOnCreateNewQuoteLegacy = async () => {
  await quoteAndBuyStore.destroyJourney()

  router.push({
    name: 'quote-add',
  })
}

const handleOnCreateNewQuote = async () => {
  if (!newFlowEnabled()) {
    await handleOnCreateNewQuoteLegacy()
    return
  }

  await basketStore.startSession()

  router.push({
    name: 'order-new',
  })
}

const buildTime = getBuildTime()
const buildVersion = getBuildVersion()

const canSeeSearch = (searchType: SearchType) => {
  let permissions = []

  switch (searchType) {
    case 'claim':
      permissions = ['claims:read', 'claims:write']
      break

    case 'policy':
      permissions = ['policies:read', 'policies:write']
      break

    case 'quote':
      permissions = ['quotes:read', 'quotes:write']
      break

    case 'customer':
      permissions = ['customers:read', 'customers:write']
      break

    case 'vet':
      permissions = ['claims:read', 'claims:write']
      break
  }

  return hasAnyPermission(permissions, agencyPermissions.value)
}

const search = (searchType: SearchType) => {
  let route = ''
  let query = ''

  switch (searchType) {
    case 'claim':
      route = 'claim-list'
      query = formData.value.claimSearchQuery

      break

    case 'policy':
      route = 'policy-list'
      query = formData.value.policySearchQuery

      break

    case 'quote':
      route = 'quote-list'
      query = formData.value.quoteSearchQuery

      break

    case 'customer':
      route = 'customer-list'
      query = formData.value.customerSearchQuery

      break

    case 'vet':
      route = 'vet-practice-list'
      query = formData.value.vetSearchQuery

      break
  }

  if (query?.length > 0 && route?.length > 0) {
    router.push({
      name: route,
      query: {
        q: query,
      },
    })
  }
}
</script>

<style lang="scss" scoped>
.dashboard {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
  padding-top: 150px;
  width: 100%;
}

.environment {
  display: flex;
  align-items: center;

  margin: var(--app-spacing-5) 0;

  img {
    margin-right: var(--app-spacing-3);
  }

  &--information {
    display: flex;
    flex-direction: column;
  }
}

.actions {
  padding-top: var(--app-spacing-4);
  display: flex;
  justify-content: flex-start;

  .action {
    display: flex;
    align-items: center;
    margin-right: var(--app-spacing-4);

    &--new-quote {
      border: 0;
      background: transparent;
      cursor: pointer;
    }

    span {
      margin-left: var(--app-spacing-2);
    }

    .el-icon {
      color: var(--el-text-color-primary);
    }
  }
}

.search-actions {
  .search-action {
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    .search-avatar {
      margin-right: var(--app-spacing-5);
      width: 64px;
      height: 64px;
    }
  }

  .search-field {
    min-width: 500px;
  }

  .customer-search-avatar {
    background-color: #e0e0ef;
  }
}
</style>
