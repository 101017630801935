<template>
  <ElContainer>
    <ElMain class="main">
      <slot />
    </ElMain>
    <ElAside v-if="$slots.aside" class="aside" width="auto">
      <slot name="aside" />
    </ElAside>
  </ElContainer>
</template>

<script>
export default {
  name: 'SimpleLayout',
}
</script>

<style lang="scss" scoped>
.main {
  padding: var(--app-container-padding);
}
.aside {
  display: flex;

  :deep() > * {
    flex: 1;
  }
}
</style>
