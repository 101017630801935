import { defineStore } from 'pinia'

import type {
  AddDataToBasketParams,
  Basket,
  BasketPetChange,
  BasketPricingBreakdown,
} from '@/@types/Basket'
import { ProductLineName } from '@/@types/Config'
import type { Lifecycle } from '@/@types/Lifecycle'
import type PaymentDetails from '@/@types/PaymentDetails'
import type { Pet, PetWithProducts } from '@/@types/Pet'
import type {
  PolicyRenewalTimelineEvent,
  TimelineEvent,
} from '@/@types/TimelineEvent'
import { useGoCardless } from '@/composables/useGoCardless'
import type {
  AccountSummary,
  AccountSummaryCustomerHydrated,
  AccountSummaryHydrated,
  AccountSummaryPetHydrated,
} from '@/domain/Account/AccountSummary'
import {
  filterActivePetsAndRemoveInactiveItems,
  getAllAccountSummaryItemsHydrated,
  inactivePets,
} from '@/domain/Account/AccountSummary'
import type { CustomerWithAddress } from '@/domain/Customer'
import { Order } from '@/domain/Order'
import type { PolicyV2 } from '@/domain/PolicyV2'
import type { Subscription } from '@/domain/Subscription'
import {
  addChangesToBasket,
  createBasket,
  fetchBasketPricingBreakdown,
  getBasket,
  patchBasketChange,
} from '@/lib/api/global/Baskets'
import { fetchHydratedLifecycles } from '@/lib/api/global/Lifecycles'
import { getCustomerProducts } from '@/lib/api/global/Products'
import { getConfigForFeature } from '@/lib/appConfig'
import dayjs from '@/lib/dayjs'
import showNotification from '@/lib/showNotification'
import { toShortUuid } from '@/lib/utils'
import { areArraysEqual, isNotNullOrUndefined } from '@/lib/utils/arrays'
import {
  getPetBundles,
  isBasketCheckedOut,
  PetBundles,
} from '@/lib/utils/basket'
import {
  findBasketChange,
  hasPolicyRenewChange,
  hasPolicyLapseChange,
  isBasketPetChange,
  isBasketPolicyChange,
  isBasketCreateChange,
  isBasketPolicyCreateChange,
} from '@/lib/utils/basket'
import {
  createPolicyLapseChange,
  createPolicyRenewChange,
  createPolicySetChange,
} from '@/lib/utils/basketChanges'
import { formatCoverageOptions } from '@/lib/utils/coverageOptions'
import {
  getRenewalFromTimeline,
  getTimelineEventsFromLifecycles,
  getPolicyTimelineEventsFromLifecycle,
} from '@/lib/utils/lifecycles'
import { formatDate } from '@/lib/utils/localisation'
import { applyChanges } from '@/lib/utils/objectChanges'
import { newFlowEnabled } from '@/lib/utils/sgp'
import AccountService from '@/services/Account/AccountService'
import AccountSummaryHydratedAdapter from '@/services/adapters/AccountSummaryHydratedAdapter'
import OrderAdapter from '@/services/adapters/OrderAdapter'
import CustomersService from '@/services/CustomersService'
import AccountSummaryPaymentsAdapter from '@/services/parsers/Account/AccountSummaryAdapter'
import PoliciesV2Service from '@/services/PoliciesV2Service'
import ShopService from '@/services/Shop/ShopService'
import SubscriptionsService from '@/services/SubscriptionsService'

import {
  AdjustProps,
  CancellationProps,
  SelectItemProps,
  useOrderOperationsStore,
} from '../orderOperations/useOrderOperations'

export type CustomerType = 'legacy' | 'non-sgp' | 'sgp'

interface BasketState {
  _customerType: Nullable<CustomerType>
  _basket: Nullable<Basket>
  _basketPricingBreakdown: Nullable<BasketPricingBreakdown>
  _customer: Nullable<CustomerWithAddress>
  _lifecycles: Lifecycle[]
  _pets: PetWithProducts[]
  _policies: PolicyV2[]
  _subscription: Nullable<Subscription>
  _subscriptionLoaded: boolean
  _loadingSession: boolean
  _customerDetails: Nullable<CustomerWithAddress>
  _paymentDetails: Nullable<PaymentDetails>
  _accountSummary: Nullable<AccountSummary>
  _accountSummaryHydrated: Nullable<AccountSummaryHydrated>
  _accountSummaryAtOrderEffectiveDate: Nullable<AccountSummary>
  _accountSummaryHydratedAtOrderEffectiveDate: Nullable<AccountSummaryHydrated>
}

const getDefaultState = (): BasketState => {
  return {
    _customerType: null,
    _basket: null,
    _basketPricingBreakdown: null,
    _customer: null,
    _lifecycles: [],
    _pets: [],
    _policies: [],
    _subscription: null,
    _subscriptionLoaded: false,
    _loadingSession: false,
    // We keep the customerDetails & paymentDetails details for new quotes outside
    // of the basket for privacy and security reasons.
    // We only send them on basket checkout.
    _customerDetails: null,
    _paymentDetails: null,
    _accountSummary: null,
    _accountSummaryHydrated: null,
    _accountSummaryAtOrderEffectiveDate: null,
    _accountSummaryHydratedAtOrderEffectiveDate: null,
  }
}

export const useBasketStore = defineStore('basket', {
  state: (): BasketState => getDefaultState(),
  getters: {
    customerType: (state: BasketState): Nullable<CustomerType> =>
      state._customerType,
    accountSummary: (state: BasketState): Nullable<AccountSummary> =>
      state._accountSummary,
    accountSummaryHydrated(): Nullable<AccountSummaryHydrated> {
      const accountSummaryHydratedIncludeInactivePets =
        this.accountSummaryHydratedIncludeInactivePets

      if (!accountSummaryHydratedIncludeInactivePets) {
        return null
      }

      return {
        ...accountSummaryHydratedIncludeInactivePets,
        pets: filterActivePetsAndRemoveInactiveItems({
          pets: accountSummaryHydratedIncludeInactivePets?.pets ?? [],
        }),
      }
    },
    accountSummaryHydratedAtOrderEffectiveDate: (
      state: BasketState
    ): Nullable<AccountSummaryHydrated> =>
      state._accountSummaryHydratedAtOrderEffectiveDate,
    accountSummaryHydratedIncludeInactivePets: (
      state: BasketState
    ): Nullable<AccountSummaryHydrated> => state._accountSummaryHydrated,
    inactivePets: (state: BasketState): AccountSummaryPetHydrated[] =>
      inactivePets({ pets: state._accountSummaryHydrated?.pets ?? [] }),
    accountSummaryCustomerHydrated: (
      state: BasketState
    ): Nullable<AccountSummaryCustomerHydrated> =>
      state._accountSummaryHydrated?.customer ?? null,
    changedAccountSummaryCustomerHydrated: (
      state: BasketState
    ): Nullable<AccountSummaryCustomerHydrated> => {
      if (!state._accountSummaryHydrated) {
        return null
      }

      const { customer } = state._accountSummaryHydrated
      const order = useOrderOperationsStore().order

      if (!order) {
        return customer
      }

      const definedOrderCustomer = Object.fromEntries(
        Object.entries(order.customer).filter(
          ([, value]) => value !== undefined
        )
      )

      return {
        ...customer,
        ...definedOrderCustomer,
      }
    },
    basketId(): Maybe<string> {
      return this._basket?.id
    },
    basketVersion(): 1 | 2 {
      return this._basket?.id.startsWith('bkt_') ? 2 : 1
    },
    basket: (state: BasketState): Nullable<Basket> => state._basket,
    allowBasketEdit(state: BasketState): boolean {
      return Boolean(state._basket && !isBasketCheckedOut(state._basket))
    },
    basketHasOnlyMtas: (state: BasketState): boolean => {
      if (!state._basket) {
        return false
      }

      if (!state._basket.changes?.length) {
        return false
      }

      return state._basket.changes.every(
        (change) =>
          (change.entity === 'PET' && change.type === 'SET') ||
          (change.entity === 'CUSTOMER' && change.type === 'SET')
      )
    },
    basketPricingBreakdown: (
      state: BasketState
    ): Nullable<BasketPricingBreakdown> => state._basketPricingBreakdown,

    paymentDetails: (state: BasketState): Nullable<PaymentDetails> =>
      state._paymentDetails,

    customer: (state: BasketState): Nullable<CustomerWithAddress> =>
      state._customer,
    customerId(): Maybe<string> {
      return this.customer?.uuid
    },
    customerDetails: (state: BasketState): Nullable<CustomerWithAddress> =>
      state._customerDetails,
    changedCustomer: (state: BasketState): Nullable<CustomerWithAddress> => {
      const change = findBasketChange(state._basket, {
        entity: 'CUSTOMER',
        entity_id: state._customer?.uuid,
        type: 'SET',
      })

      if (!state._customer || !change) {
        return state._customer
      }

      return applyChanges(
        state._customer,
        change.data as Partial<CustomerWithAddress>
      )
    },

    pets: (state: BasketState): Pet[] => state._pets,
    changedPets: (state: BasketState): Pet[] => {
      return state._pets.map((pet) => {
        const change = findBasketChange(state._basket, {
          entity: 'PET',
          entity_id: pet.id,
          type: 'SET',
        })

        if (!change) {
          return pet
        }

        return applyChanges(pet, change.data as Partial<Pet>)
      })
    },
    changedHydratedPets: (state: BasketState): AccountSummaryPetHydrated[] => {
      if (!state._accountSummaryHydrated) {
        return []
      }

      const order = useOrderOperationsStore().order

      if (!order) {
        return state._accountSummaryHydrated.pets
      }

      const result = state._accountSummaryHydrated.pets.map((pet) => {
        const petInOrder = order.pets.find((orderPet) => orderPet.id === pet.id)

        if (!petInOrder) {
          return pet
        }

        const defindeOrderPet = Object.fromEntries(
          Object.entries(pet).filter(([, value]) => value !== undefined)
        )

        return {
          ...pet,
          ...defindeOrderPet,
          items: pet.items,
        }
      })

      return result
    },
    petsBundles: ({
      _basket: basket,
      _pets: pets,
      _lifecycles: lifecycles,
    }: BasketState): Nullable<PetBundles[]> =>
      basket && getPetBundles(basket, pets, lifecycles),
    basketPets: ({
      _basket: basket,
      _pets: pets,
    }: BasketState): BasketPetChange[] => {
      if (!basket) {
        return []
      }

      const petChanges = basket.changes.filter(isBasketPetChange)

      return petChanges.map((pet) => {
        const key = (pet.entity_id ?? pet.correlation_id) as string

        return {
          ...pet,

          key,

          name:
            pet.type === 'SET'
              ? pets.find(({ id }) => id === pet.entity_id)?.name
              : pet.data.name,

          basketPolicies: basket.changes
            .filter(isBasketPolicyChange)
            .filter((change) => change.data?.pet?.id === key)
            .map((change) => {
              if (!change.ratings) {
                return null
              }

              const rating = change.ratings[basket.effective_date]

              const coverageOptions = formatCoverageOptions(
                rating.coverage_options
              )

              return {
                ...change,
                bundles: rating.bundles.map((bundle) => ({
                  ...bundle,
                  key: bundle.compulsory.join(','),
                  compulsory: bundle.compulsory.map(
                    (key: string) => coverageOptions[key]
                  ),
                  optional: bundle.optional.map(
                    (key: string) => coverageOptions[key]
                  ),
                  selected: areArraysEqual(
                    bundle.compulsory,
                    change.data.coverages ?? []
                  ),
                })),
              }
            })
            .filter(isNotNullOrUndefined),
        }
      })
    },

    policies: (state: BasketState): PolicyV2[] => state._policies,

    subscription: (state: BasketState): Nullable<Subscription> =>
      state._subscription,
    subscriptionLoaded: (state: BasketState): boolean =>
      state._subscriptionLoaded,

    policiesRequestedToLapse(): PolicyRenewalTimelineEvent[] {
      return this.renewals.filter((renewal) =>
        hasPolicyLapseChange(this.basket, renewal.entity_id)
      )
    },
    policiesRequestedToRenew(): PolicyRenewalTimelineEvent[] {
      return this.renewals.filter((renewal) =>
        hasPolicyRenewChange(this.basket, renewal.entity_id)
      )
    },

    loadingSession: (state: BasketState): boolean => state._loadingSession,
    timeline: (state: BasketState): TimelineEvent[] =>
      getTimelineEventsFromLifecycles(state._lifecycles),
    lifecycles: (state: BasketState): Lifecycle[] => state._lifecycles,
    renewals: (state: BasketState): PolicyRenewalTimelineEvent[] =>
      state._lifecycles
        .map(getPolicyTimelineEventsFromLifecycle)
        .map(getRenewalFromTimeline)
        .filter(isNotNullOrUndefined),
    isSgp: (state: BasketState): boolean =>
      Boolean(state._accountSummaryHydrated?.isSgp),

    isSgpMigrated: (state: BasketState): boolean =>
      state._lifecycles.some((lifecycle) =>
        Boolean(
          'metadata' in lifecycle.connections[0] &&
            lifecycle.connections[0].metadata?.iba_migration
        )
      ),

    customerHasAnyNonNewFlowEnabledProductLines: (
      state: BasketState
    ): boolean => {
      // if the customer has any product lines that are not new flow enabled, return true
      const productLinesOnCustomer =
        (state._policies?.map(
          (policy) => policy.product_line
        ) as ProductLineName[]) ?? []

      const newFlowEnabledProductLines =
        (getConfigForFeature(
          'newFlowEnabledProductLines'
        ) as ProductLineName[]) ?? []

      const result =
        productLinesOnCustomer.length > 0 &&
        productLinesOnCustomer.some(
          (productLineOnCustomer) =>
            !newFlowEnabledProductLines.includes(productLineOnCustomer)
        )

      return result
    },
    allowSgpActionsOnCustomer: (state: BasketState): boolean => {
      // if the customer is SGP and the user does not have the newFlowEnabled flag, dissalow actions
      if (state._accountSummaryHydrated?.isSgp && !newFlowEnabled()) {
        return false
      }

      return true
    },
    isAllNewBusiness: ({ _basket }: BasketState): boolean =>
      _basket?.changes.every(isBasketCreateChange) ?? false,
    hasNewBusiness: ({ _basket }: BasketState): boolean =>
      _basket?.changes.some(isBasketPolicyCreateChange) ?? false,
    order: (): Nullable<Order> => useOrderOperationsStore().order,
    allowPaymentSummarySidebar(state: BasketState): boolean {
      if (this.isSgp) {
        return true
      }

      // We do not care to display this for SE or for UK customers with only cat-dog
      if (state._customerType === 'legacy') {
        return false
      }

      // If there is no order/basket in progress we allow the sidebar because it only shows the summary
      if (!state._basket) {
        return true
      }

      return true
    },
  },
  actions: {
    resetState(): void {
      this.$reset()
      useOrderOperationsStore().reset()
    },
    setBasket(basket: Basket | null): void {
      this._basket = basket
    },
    setBasketAndLoadData(basket: Basket | null): void {
      // If the new basket's ID differs from that of the existing one, unset any
      // stored pricing breakdown.
      if (this.basketId !== basket?.id) {
        this._basketPricingBreakdown = null
      }

      this.setBasket(basket)

      if (basket != null) {
        this.loadBasketPricingBreakdown(basket.id)
        if (this.customerId) {
          this.loadCustomerSubscriptionOnce(this.customerId)
        }
      }
    },
    setOrder(order: Order | null): void {
      useOrderOperationsStore().order = order
    },
    setBasketPricingBreakdown(
      basketPricingBreakdown: Nullable<BasketPricingBreakdown>
    ): void {
      this._basketPricingBreakdown = basketPricingBreakdown
    },
    setCustomer(customer: Nullable<CustomerWithAddress>): void {
      this._customer = customer
    },
    setPaymentDetails(paymentDetails: PaymentDetails): void {
      this._paymentDetails = paymentDetails
    },
    setPets(pets: PetWithProducts[]): void {
      this._pets = pets
    },
    setPolicies(policies: PolicyV2[]): void {
      this._policies = policies
    },
    setCustomerDetails(customerDetails: CustomerWithAddress): void {
      this._customerDetails = customerDetails
    },
    setSubscription(subscription: Nullable<Subscription>): void {
      this._subscription = subscription
    },
    setSubscriptionLoaded(subscriptionLoaded: boolean): void {
      this._subscriptionLoaded = subscriptionLoaded
    },
    setLifecycles(lifecycles: Lifecycle[]): void {
      this._lifecycles = lifecycles
    },
    setAccountSummary(accountSummary: Nullable<AccountSummary>): void {
      this._accountSummary = accountSummary
    },
    setAccountSummaryHydrated(
      accountSummaryHydrated: Nullable<AccountSummaryHydrated>
    ): void {
      this._accountSummaryHydrated = accountSummaryHydrated
    },

    setAccountSummaryAtOrderEffectiveDate(
      accountSummary: Nullable<AccountSummary>
    ): void {
      this._accountSummaryAtOrderEffectiveDate = accountSummary
    },

    setAccountSummaryHydratedAtOrderEffectiveDate(
      accountSummaryHydrated: Nullable<AccountSummaryHydrated>
    ): void {
      this._accountSummaryHydratedAtOrderEffectiveDate = accountSummaryHydrated
    },
    setLoadingSession(loadingSession: boolean): void {
      this._loadingSession = loadingSession
    },
    async startSession({
      customerId,
      basketId,
    }: {
      customerId?: string
      basketId?: string
    } = {}): Promise<void> {
      try {
        this.resetState()
        this.setLoadingSession(true)

        // If we have both customer and basket, we load everything at once.
        // If we only have basket, we load the basket. And if the basket is owner we then laad the customer(owner).
        // If we only have customer, we load the customer.
        if (customerId && basketId) {
          await this.loadCustomerContext({
            customerId,
          })
          await this.loadBasketAndOrderContext({ basketId })
        } else if (basketId) {
          await this.loadBasketAndOrderContext({ basketId })

          if (this.basket?.owner) {
            await this.loadCustomerContext({
              customerId: this.basket.owner,
            })
          }
        } else if (customerId) {
          await this.loadCustomerContext({ customerId })
        }

        // If we somehow end up with mismatching basket owner - we reset the state and throw an error
        if (this.basket?.owner && this.basket.owner !== this.customerId) {
          // We need to generate the error message before we reset the state
          const errorMessage = `Basket owner "${this.basket.owner}" does not match customer id "${this.customerId}"`
          this.resetState()
          throw new Error(errorMessage)
        }
      } finally {
        this.setLoadingSession(false)
      }
    },
    async loadCustomer(customerId: string): Promise<CustomerWithAddress> {
      this.setCustomer(null)

      const customer = await CustomersService.getCustomerWithAddress(customerId)
      this.setCustomer(customer)
      return customer
    },
    async loadCustomerPets(customerId: string): Promise<void> {
      this.setPets([])

      const pets = await getCustomerProducts(customerId)
      this.setPets(pets)
    },
    async loadCustomerPolicies(customerId: string): Promise<void> {
      this.setPolicies([])

      const policies = await PoliciesV2Service.getAllPoliciesForCustomer({
        customerId,
      })

      if (policies) {
        this.setPolicies(policies)
      }
    },
    async loadLifecycles(customerId: string): Promise<void> {
      this.setLifecycles([])

      const lifecycles = await fetchHydratedLifecycles({
        customerId: customerId,
      })
      this.setLifecycles(lifecycles)
    },
    async loadAccountSummary(customerId: string): Promise<void> {
      this.setAccountSummary(null)

      const shortCustomerId = toShortUuid(customerId, 'cus')

      const accountSummary =
        await AccountService.getAccountSummary(shortCustomerId)

      if (getConfigForFeature('flags.enableRetainedPremiumsCalculations')) {
        const adaptedWithpaymentsSummary =
          AccountSummaryPaymentsAdapter.adaptPayments(accountSummary)

        this.setAccountSummary(adaptedWithpaymentsSummary)
        return
      }

      this.setAccountSummary(accountSummary)
    },

    async loadAccountSummaryAtOrderEffectiveDate(
      customerId: string,
      date: string
    ): Promise<void> {
      this.setAccountSummary(null)

      const shortCustomerId = toShortUuid(customerId, 'cus')

      const accountSummary = await AccountService.getAccountSummary(
        shortCustomerId,
        date
      )

      if (getConfigForFeature('flags.enableRetainedPremiumsCalculations')) {
        const adaptedWithpaymentsSummary =
          AccountSummaryPaymentsAdapter.adaptPayments(accountSummary)

        this.setAccountSummaryAtOrderEffectiveDate(adaptedWithpaymentsSummary)
        return
      }

      this.setAccountSummaryAtOrderEffectiveDate(accountSummary)
    },
    async loadAccountSummaryAtRenewalDate(customerId: string): Promise<void> {
      this.setAccountSummary(null)

      const shortCustomerId = toShortUuid(customerId, 'cus')

      const accountSummary =
        await AccountService.getAccountSummary(shortCustomerId)

      if (getConfigForFeature('flags.enableRetainedPremiumsCalculations')) {
        const adaptedWithpaymentsSummary =
          AccountSummaryPaymentsAdapter.adaptPayments(accountSummary)

        this.setAccountSummary(adaptedWithpaymentsSummary)
        return
      }

      this.setAccountSummary(accountSummary)
    },

    async getNonSgpCustomerType(params: {
      policies: PolicyV2[]
    }): Promise<Nullable<CustomerType>> {
      const { policies } = params
      if (!this.customer) {
        return null
      }

      const productLinesOnCustomer = policies.map(
        (policy) => policy.product_line
      ) as ProductLineName[]

      const legacyProductLines = getConfigForFeature(
        'legacyProductLines'
      ) as ProductLineName[]

      const isLegacyCustomer =
        productLinesOnCustomer.length > 0 &&
        productLinesOnCustomer.every((productLineOnCustomer) => {
          return legacyProductLines.includes(productLineOnCustomer)
        })

      if (
        isLegacyCustomer ||
        getConfigForFeature('flags.forceDisableNewFlow')
      ) {
        return 'legacy'
      }

      return 'non-sgp'
    },

    async hydrateAccountSummary(params: {
      accountSummary: Nullable<AccountSummary>
    }): Promise<AccountSummaryHydrated> {
      const { accountSummary } = params

      if (!this.customer) {
        throw new Error('Customer not loaded')
      }

      let adapter: Nullable<AccountSummaryHydratedAdapter> = null

      switch (this.customerType) {
        case 'sgp':
          if (!accountSummary) {
            throw new Error('Account summary not loaded')
          }

          adapter = new AccountSummaryHydratedAdapter()
          return await adapter.hydrateAccountSummary({
            accountSummary: accountSummary,
            lifecycles: this.lifecycles,
            isSgp: true,
          })

        case 'non-sgp':
          if (!accountSummary) {
            throw new Error('Account summary not loaded')
          }
          // Ensure policies and subscription are loaded for non-sgp customers
          if (!this.policies) {
            throw new Error('Policies not loaded')
          }

          adapter = new AccountSummaryHydratedAdapter(
            this.subscription ?? undefined
          )
          return await adapter.hydrateAccountSummary({
            accountSummary: accountSummary,
            lifecycles: this.lifecycles,
            isSgp: false,
          })

        case 'legacy':
          adapter = new AccountSummaryHydratedAdapter()
          return adapter.hydrateAccountSummaryLegacy({
            customer: this.customer,
          })

        default:
          throw new Error('Invalid customer type')
      }
    },

    async loadAccountSummaryHydrated(): Promise<void> {
      this.setAccountSummaryHydrated(null)

      const result = await this.hydrateAccountSummary({
        accountSummary: this.accountSummary,
      })

      this.setAccountSummaryHydrated(result)
    },

    async loadAccountSummaryHydratedAtOrderEffectiveDate(): Promise<void> {
      this.setAccountSummaryHydratedAtOrderEffectiveDate(null)

      if (!this._accountSummaryAtOrderEffectiveDate) {
        return
      }

      const result = await this.hydrateAccountSummary({
        accountSummary: this._accountSummaryAtOrderEffectiveDate,
      })

      this.setAccountSummaryHydratedAtOrderEffectiveDate(result)
    },
    async loadCustomerContext({
      customerId,
    }: {
      customerId: string
    }): Promise<void> {
      const customerPromises: Array<Promise<CustomerWithAddress | boolean>> = [
        this.loadCustomer(customerId),
      ]

      if (!getConfigForFeature('flags.forceDisableNewFlow')) {
        customerPromises.push(
          ShopService.isCustomerSgp({
            customerId: toShortUuid(customerId, 'cus'),
          })
        )
      }

      const agencyHasSgpProductLines =
        ((getConfigForFeature('sgpProductLines') as ProductLineName[]) ?? [])
          .length > 0

      const [, isCustomerSgp = false] = await Promise.all(customerPromises)

      if (isCustomerSgp && agencyHasSgpProductLines) {
        this._customerType = 'sgp'
        const promises = [
          this.loadCustomerSubscriptionOnce(customerId), // for change subscription modal only
          this.loadLifecycles(customerId),
          this.loadAccountSummary(customerId),
        ]

        await Promise.all(promises)
        await this.loadAccountSummaryHydrated()

        return
      }

      // if it's not sgp we need the policies to figure out whether this is new or old
      // becase cat-dog-pio customers are new flow but cat-dog customers aren't
      await this.loadCustomerPolicies(customerId)

      this._customerType = await this.getNonSgpCustomerType({
        policies: this.policies,
      })

      if (this._customerType === 'non-sgp') {
        const promises = [
          this.loadCustomerSubscriptionOnce(customerId),
          this.loadLifecycles(customerId),
          this.loadAccountSummary(customerId),
          this.loadCustomerPets(customerId), //for US we need this for edit renewals only
        ]

        await Promise.all(promises)
        await this.loadAccountSummaryHydrated()

        return
      }

      if (this._customerType === 'legacy') {
        // we only want to see policies and customer
        // what about cat-dog-pio and cat-dog combo??
        const promises = [
          this.loadCustomer(customerId),
          this.loadLifecycles(customerId),
        ]

        await Promise.all(promises)
        await this.loadAccountSummaryHydrated()

        return
      }
    },
    async loadBasketAndOrderContext({
      basketId,
    }: {
      basketId: string
    }): Promise<void> {
      await this.loadBasket(basketId)
    },
    async loadBasketPricingBreakdown(
      basketId: string
    ): Promise<Nullable<BasketPricingBreakdown>> {
      this.setBasketPricingBreakdown(null)

      const pricingBreakdown = await fetchBasketPricingBreakdown({
        basket: basketId,
      })

      this.setBasketPricingBreakdown(pricingBreakdown)

      return pricingBreakdown
    },
    async loadCustomerSubscription(customerId: string): Promise<void> {
      this.setSubscription(null)

      const subscriptions = await SubscriptionsService.getSubscriptions({
        owner: customerId,
        status: 'ACTIVE',
      })

      if (subscriptions?.length) {
        this.setSubscription(subscriptions[0])
      }
    },
    async loadCustomerSubscriptionOnce(customerId: string): Promise<void> {
      if (customerId !== this.customerId || !this.subscriptionLoaded) {
        this.setSubscriptionLoaded(true)

        await this.loadCustomerSubscription(customerId)
      }
    },
    async loadBasket(basketId: string): Promise<void> {
      const promises: [
        Promise<Basket>,
        Promise<Nullable<Order>>,
        Promise<Nullable<BasketPricingBreakdown>>,
      ] = [
        getBasket(basketId),
        this.loadOrder(basketId),
        this.loadBasketPricingBreakdown(basketId),
      ] as const

      const [
        loadBasketResponse,
        loadOrderResponse,
        loadBasketPricingBreakdownResponse,
      ]: [
        PromiseSettledResult<Basket>,
        PromiseSettledResult<Nullable<Order>>,
        PromiseSettledResult<Nullable<BasketPricingBreakdown>>,
      ] = await Promise.allSettled(promises)

      if (
        (loadBasketResponse.status === 'rejected' ||
          loadBasketPricingBreakdownResponse.status === 'rejected') &&
        loadOrderResponse.status === 'fulfilled'
      ) {
        this.setOrder(loadOrderResponse.value)

        return
      }

      if (loadBasketResponse.status === 'rejected') {
        // If Basket call failed we throw the error
        throw loadBasketResponse.reason
      }

      if (loadBasketPricingBreakdownResponse.status === 'rejected') {
        // If Basket Pricing Breakdown call failed we throw the error
        throw loadBasketPricingBreakdownResponse.reason
      }

      if (loadOrderResponse.status === 'rejected') {
        // If the basket loaded fine, but the order fails, swallow the order
        // error in known error conditions (here: when the basket contains
        // renewals), else re-throw it.
        throw loadOrderResponse.reason
      }

      this.setBasket(loadBasketResponse.value)
    },
    async loadOrder(basketId: string): Promise<Nullable<Order>> {
      if (getConfigForFeature('flags.forceDisableNewFlow')) {
        return null
      }

      const order = await ShopService.getOrderForBasketV2({
        basketId,
      })

      await this.setAndHydrateOrder(order)

      if (
        this.customerId &&
        order?.effectiveDate &&
        dayjs(order.effectiveDate).isAfter(dayjs(), 'day')
      ) {
        try {
          await this.loadAccountSummaryAtOrderEffectiveDate(
            this.customerId,
            order.effectiveDate
          )
          await this.loadAccountSummaryHydratedAtOrderEffectiveDate()
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
        } catch (error: unknown) {
          // IGNORE error because we'll just default for summary today
          // this fails for CDP and might also fail in US for some future operations
        }
      }

      return this.order
    },
    async setAndHydrateOrder(order: Order | null): Promise<void> {
      if (order === null) {
        this.setOrder(null)

        return
      }

      try {
        const orderWithCatalogDetails =
          await OrderAdapter.addCatalogDetailsToOrder({
            order,
          })

        this.setOrder(orderWithCatalogDetails)
      } catch {
        // swallow error and set the order unhydrated
        this.setOrder(order)
      }
    },
    async addDataToBasket(data: AddDataToBasketParams): Promise<Basket | null> {
      let basket: Basket | null = this.basket

      if (this.basketId) {
        if (
          this.basket &&
          data.effective_date &&
          data.effective_date !== this.basket.effective_date
        ) {
          throw new Error(
            `The current basket effective date ${formatDate(
              this.basket.effective_date
            )} is different than this change's effective date ${formatDate(
              data.effective_date
            )}.`
          )
        }

        const newChanges = []

        for (const change of data.changes) {
          const basketChange = findBasketChange(this.basket, change)
          if (basketChange?.id) {
            basket = await patchBasketChange({
              basket: this.basketId,
              change: basketChange.id,
              data: change,
            })
          } else {
            newChanges.push(change)
          }
        }

        if (newChanges.length) {
          basket = await addChangesToBasket({
            basket: this.basketId,
            changes: newChanges,
          })
        }
      } else {
        basket = await createBasket({
          effective_date: dayjs().format('YYYY-MM-DD'),
          ...data,
          owner: this.customerId,
        })
      }

      this.setBasketAndLoadData(basket)

      if (basket) {
        await this.loadOrder(basket.id)
      }

      return basket
    },
    // This should act as a proxy for order operation store
    // once all the basketv2 operations get removed
    // we will use directly useOrderOperationsStore
    // in our components
    async updateOrderEffectiveDate({
      effectiveDate,
    }: {
      effectiveDate: string
    }): Promise<void> {
      const order = await useOrderOperationsStore().updateOrderEffectiveDate({
        effectiveDate,
        basketVersion: this.basketVersion,
      })

      if (order) {
        if (
          this.customerId &&
          order.effectiveDate &&
          dayjs(order.effectiveDate).isAfter(dayjs(), 'day')
        ) {
          try {
            await this.loadAccountSummaryAtOrderEffectiveDate(
              this.customerId,
              order.effectiveDate
            )
            await this.loadAccountSummaryHydratedAtOrderEffectiveDate()
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
          } catch (error: unknown) {
            // IGNORE error because we'll just default for summary today
            // this fails for CDP and might also fail in US for some future operations
          }
        }
      }
    },
    async changePaymentDate({
      paymentDay,
    }: {
      paymentDay: number
    }): Promise<Order | undefined> {
      if (!this.customerId) {
        return
      }
      const shortCustomerId = toShortUuid(this.customerId, 'cus')
      const effectiveDate =
        this.getEarliestValidEffectiveDate().format('YYYY-MM-DD')

      const order =
        await useOrderOperationsStore().createChangePaymentDateOrder({
          effectiveDate,
          paymentDay,
          customerId: shortCustomerId,
          basketVersion: this.basketVersion,
        })
      return order
    },
    getEarliestValidEffectiveDate(): dayjs.Dayjs {
      const allItems = this.accountSummaryHydrated
        ? getAllAccountSummaryItemsHydrated({
            accountSummaryHydrated: this.accountSummaryHydrated,
          })
        : []

      let effectiveDate = dayjs()

      if (allItems.length > 0) {
        const maxInceptionDate = allItems.reduce((max, item) => {
          const itemInceptionDate = item.startDate
            ? dayjs(item.startDate)
            : null
          return itemInceptionDate && itemInceptionDate.isAfter(max)
            ? itemInceptionDate
            : max
        }, dayjs())

        if (maxInceptionDate.isAfter(effectiveDate)) {
          effectiveDate = maxInceptionDate
        }
      }

      return effectiveDate
    },
    async editCustomerOrPets({
      newCustomer,
      newPets,
    }: {
      newCustomer: Partial<AccountSummaryCustomerHydrated>
      newPets?: Partial<AccountSummaryPetHydrated>[]
    }): Promise<void> {
      const order =
        await useOrderOperationsStore().createEditCustomerOrPetOrder({
          newCustomer: newCustomer,
          newPets: newPets,
          effectiveDate:
            this.getEarliestValidEffectiveDate().format('YYYY-MM-DD'),
        })
      if (order && order.basketId) {
        await this.loadBasketAndOrderContext({
          basketId: order.basketId,
        })
      }
    },

    async editPaymentMethod(): Promise<void> {
      if (!this.accountSummaryCustomerHydrated) {
        throw new Error('Customer not loaded.')
      }

      const { openGoCardlessModal, closeGoCardlessModal } = useGoCardless()

      try {
        const billingRequestFlowId = await AccountService.editPaymentDetails(
          this.accountSummaryCustomerHydrated.id
        )

        this.setLoadingSession(true)

        const { billingRequest } =
          await openGoCardlessModal(billingRequestFlowId)

        closeGoCardlessModal()

        await AccountService.updatePaymentDetails({
          customerId: this.accountSummaryCustomerHydrated.id,
          paymentMethodId: billingRequest.id as string, // it will throw if there's no id
        })

        showNotification({
          title: 'Payment method update',
          type: 'success',
          message:
            'Payment method successfully updated. Changes may not be applied immediately.',
          duration: 6000,
        })
      } catch (error) {
        closeGoCardlessModal()
        throw error
      } finally {
        this.setLoadingSession(false)
      }
    },

    async addPolicyLapseChange({
      entity_id,
      date,
      reason,
    }: {
      entity_id: string
      date: string
      reason: string
    }): Promise<Basket | null> {
      const change = createPolicyLapseChange({ entity_id, reason })
      return await this.addDataToBasket({
        changes: [change],
        effective_date: date,
      })
    },

    orderAlreadyHasCancellation(): boolean {
      return useOrderOperationsStore().orderAlreadyHasCancellation
    },

    async cancelProduct(props: CancellationProps): Promise<void> {
      const order = await useOrderOperationsStore().cancelProduct(props)
      if (order && order.basketId) {
        await this.loadBasketAndOrderContext({
          basketId: order.basketId,
        })
      }
    },
    async adjustProduct(props: AdjustProps): Promise<Maybe<Order>> {
      const order = await useOrderOperationsStore().adjustProduct(props)
      if (order && order.basketId) {
        await this.loadBasketAndOrderContext({
          basketId: order.basketId,
        })
      }

      return order
    },
    async selectProducts(props: SelectItemProps): Promise<Maybe<Order>> {
      const order = await useOrderOperationsStore().selectProducts(props)

      if (order && order.basketId) {
        await this.loadBasketAndOrderContext({
          basketId: order.basketId,
        })
      }

      return order
    },
    async editPolicyLapseChange({
      entity_id,
      reason,
    }: PolicyRenewalTimelineEvent & { reason: string }): Promise<void> {
      const currentLapseChange = this.basket?.changes.find(
        (change) => change.entity_id === entity_id
      )

      const basket = await patchBasketChange({
        basket: this.basketId as string,
        change: currentLapseChange?.id as string,
        data: {
          ...currentLapseChange?.data,
          reason,
        },
      })

      this.setBasketAndLoadData(basket)
    },
    async addPolicyRenewChange({
      entity_id,
      date,
    }: PolicyRenewalTimelineEvent): Promise<void> {
      const change = createPolicyRenewChange({ entity_id })

      await this.addDataToBasket({
        changes: [change],
        effective_date: date,
      })
    },
    async addPolicyRenewSetChange({
      entity_id,
      date,
    }: PolicyRenewalTimelineEvent): Promise<string | undefined> {
      const change = createPolicySetChange({ entity_id })

      const data = {
        effective_date: date,
        changes: [change],
      }

      const basket = await this.addDataToBasket(data)

      return basket?.id
    },

    async selectBasketItemCoverages({
      policyChangeId,
      coverages,
      product,
    }: {
      policyChangeId?: string
      coverages: string[]
      product: string
    }): Promise<void> {
      const change = (<Basket>this.basket)?.changes
        ?.filter(isBasketPolicyChange)
        .find((change) => change.id === policyChangeId)

      if (change && policyChangeId) {
        const basket = await patchBasketChange({
          basket: this.basketId as string,
          change: policyChangeId,
          data: {
            data: {
              ...change.data,
              coverages,
              product,
            },
          },
        })

        this.setBasketAndLoadData(basket)
      }
    },
    async checkoutBasket(): Promise<void> {
      if (!this.basketId) {
        throw new Error('There is no basket to purchase.')
      }

      const orderId = this.basketId.replace('bkt_', 'ord_')
      await ShopService.purchaseOrderFromV2Basket({
        orderId,
      })

      this.setBasketAndLoadData(null)
    },
    async syncCustomerToIBSuite(customerId: string): Promise<void> {
      await CustomersService.syncToIBSuite(customerId)

      const customer = await CustomersService.getCustomerWithAddress(customerId)

      if (customer) {
        this.setCustomer(customer)
      }
    },
  },
  persist: false,
})
