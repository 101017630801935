import { BasketPolicyChange } from '@/@types/Basket'

export function createPolicyLapseChange({
  entity_id,
  reason,
}: {
  entity_id: string
  reason: string
}): BasketPolicyChange {
  return {
    type: 'LAPSE',
    entity: 'POLICY',
    entity_id,
    data: {
      cancellation_reason: reason,
    },
  }
}

export function createPolicySetChange({
  entity_id,
}: {
  entity_id: string
}): BasketPolicyChange {
  return {
    type: 'SET',
    entity: 'POLICY',
    entity_id,
    data: {},
  }
}

export function createPolicyRenewChange({
  entity_id,
}: {
  entity_id: string
}): BasketPolicyChange {
  return {
    type: 'RENEW',
    entity: 'POLICY',
    entity_id,
    data: {},
  }
}
