import { InstanceConfig } from '@/@types/Config'
import deepFreeze from '@/lib/utils/deepFreeze'
import { AVAILABLE_CLAIM_FLAG_OPTIONS } from '@/novelClaims/domain/Claim'
import { AVAILABLE_LOSS_FLAG_OPTIONS } from '@/novelClaims/domain/Loss'

const instanceConfig: InstanceConfig = {
  isProductionInstance: false,
  ibsuitePolicyEditUrl:
    'https://preprod.ibapplications.com/Policies/editPolicy.action?policy.id=${policy_id}',
  cyEditUrl:
    'https://preprod.ibapplications.com/Policies/editPolicy.action?policy.id=${policy_id}',
  ibsuiteCustomerEditUrl:
    'https://preprod.ibapplications.com/Customers/editCustomer360.action?customer.id=${customer_id}',
  pioAdminApiUrl: 'https://admin-api.stagpolicies.io',
  pioShopApiUrl: 'https://shop.eu-test.policies.io',
  pioShopAccountApiKey: 'q3aJPjY1pVaUSIFBH6qOJ20D5vtTOQEn9D3UT00R',
  pioCustomerApiUrl: 'https://customer.eu-test.policies.io',
  pioAccountApiUrl: 'https://account.eu-test.policies.io',
  useTimelines: true,
  agencySecretKeyArn:
    'arn:aws:kms:eu-west-1:330612717183:key/b1e2cf87-0f1f-484e-a198-93e3ce1421f9',
  allowEditVetPracticeByNonSupervisor: false,
  defaultClaimNoteCategory: null,
  autoPopulateTreatmentEndDate: false,
  'claim-document-request.is-enabled': true,
  'vet-practice.general-email.is-required': true,
  'policy-limits-warnings.is-enabled': true,
  'claim-pet-waiting-period-messages.is-enabled': false,
  'page-viewers.is-enabled': true,
  'claim-display-policy-discrepancies.is-enabled': true,
  'claim-medical-history-conditions.is-enabled': true,
  'claim-millie-status.is-enabled': true,
  'claim-inner-limits.is-enabled': true, // managed in LaunchDarkly
  allowMultiLossCoverageCalculation: false,
  trackedPolicyCoverageSubcoverages: {
    vet_fees: ['complementary_treatment', 'prescribed_food'],
    'vet-fees': ['complementary_treatment', 'prescribed_food'],
  },
  claimFlagOptions: [
    AVAILABLE_CLAIM_FLAG_OPTIONS.APPROVED_UNDER_EXCESS,
    AVAILABLE_CLAIM_FLAG_OPTIONS.COMPLAINT,
    AVAILABLE_CLAIM_FLAG_OPTIONS.DECEASED,
    AVAILABLE_CLAIM_FLAG_OPTIONS.FRAUD_THIRD_PARTY_NON_CUSTOMER,
    AVAILABLE_CLAIM_FLAG_OPTIONS.HIGH_VALUE,
    AVAILABLE_CLAIM_FLAG_OPTIONS.INVESTIGATION_NEEDED,
    AVAILABLE_CLAIM_FLAG_OPTIONS.POTENTIAL_RECOVERY,
    AVAILABLE_CLAIM_FLAG_OPTIONS.PRE_EXISTING,
    AVAILABLE_CLAIM_FLAG_OPTIONS.REOPENED,
    AVAILABLE_CLAIM_FLAG_OPTIONS.VULNERABLE_CUSTOMER,
  ],
  lossFlagOptions: [
    AVAILABLE_LOSS_FLAG_OPTIONS.ALTERNATE_PAYEE,
    AVAILABLE_LOSS_FLAG_OPTIONS.APPROVED_UNDER_EXCESS,
    AVAILABLE_LOSS_FLAG_OPTIONS.POSTCODE_DISCREPANCY,
    AVAILABLE_LOSS_FLAG_OPTIONS.PRESCRIPTION,
    AVAILABLE_LOSS_FLAG_OPTIONS.REFUND_OF_MILLIE_DEDUCTION,
  ],
  vetIntegrations: [
    {
      name: 'Cat Dog',
      productLineKey: 'cat-dog',
      source: 'CATDOG',
      key: 'vet_covea_id',
    },
    {
      name: 'Cat Dog PIO',
      productLineKey: 'cat-dog-pio',
      source: 'CATDOGPIO',
      key: 'vet_algolia_id',
    },
  ],
  websocketApiUrl: 'wss://ojrd9vewbi.execute-api.eu-west-1.amazonaws.com/test',
  heap: {
    environmentId: '2617005862',
    'is-enabled': true,
  },
}

export default deepFreeze(instanceConfig)
